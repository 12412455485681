import './style.css'
// import './itc-custom-select.js'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'

import firefliesFragmentShader from './shaders/fireflies/fragment.glsl'
import firefliesVertexShader from './shaders/fireflies/vertex.glsl'
import SmokeFragmentShader from './shaders/smoke/fragment.glsl'
import SmokeVertexShader from './shaders/smoke/vertex.glsl'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'

import gsap from 'gsap'
import { Water } from 'three/examples/jsm/objects/Water2.js'
import Stats from 'three/examples/jsm/libs/stats.module.js'


// import {guides} from  './guides.js' надо подключать jquery


/**
 * Stats
 */
 const stats = new Stats()
 stats.showPanel(0)
//  document.body.appendChild(stats.dom)
//  let ttt =  stats.getFPS()


const SelectorPechMat = document.querySelector('select')
const ButtonsSize = document.querySelectorAll('.button-size')
const ButtonsPodstavka = document.querySelectorAll('.button-podstavka')
const ButtonsPechka = document.querySelectorAll('.button-pechka')
const ButtonsMat = document.querySelectorAll('.button-mat')
const ButtonsDoors = document.querySelectorAll('.button-doors')
const ButtonsSpinka = document.querySelectorAll('.button-spinka')
const PechkaMatNerzh = document.querySelector('.nerzh')
const PechkaMatChern = document.querySelector('.chermet')
const loadingWater = document.querySelector('.water-loader')
const ButtonsChair = document.querySelectorAll('.button-chair')

const Body = document.querySelector('body')
const CanvasDiv = document.querySelector('.canvas-div')
const ButtonsStairs = document.querySelectorAll('.button-stairs')
const ButtonsTent = document.querySelectorAll('.button-tent')

const Loader = document.querySelector('.loader')
const Price = document.getElementById('price')

const SizeSetup = document.querySelector('.size-setup')
const PodstavkaSetup = document.querySelector('.podstavka-setup')
const PechkaSetup = document.querySelector('.pechka-setup')
const MatSetup = document.querySelector('.mat-setup')
const DoorsSetup = document.querySelector('.doors-setup')
const SpinkaSetup = document.querySelector('.spinka-setup')
const StairsSetup = document.querySelector('.stairs-setup')
const TentSetup = document.querySelector('.tent-setup')
const ChairSetup = document.querySelector('.chair-setup')


// Размеры и лейблы
const ChanPriceLabel = document.getElementById('chasha-price')
const ChanSizeLabel = document.getElementById('chasha-size') 

const PodstavkaPriceLabel = document.getElementById('podstavka-price')
const PodstavkaMatLabel = document.getElementById('podstavka-mat') 

const PechkaPriceLabel = document.getElementById('pechka-price')
const PechkaMatLabel = document.getElementById('pechka-mat') 

const MatBlock = document.querySelector('.block-mat')
const PechkaMatPriceLabel = document.getElementById('material-price')
const PechkaMaterialLabel = document.getElementById('material-mat') 

const DoorBlock = document.querySelector('.block-doors')
const DoorPriceLabel = document.getElementById('doors-price')
const DoorMatLabel = document.getElementById('doors-mat') 

const SpinkaPriceLabel = document.getElementById('spinka-price')
const SpinkaMatLabel = document.getElementById('spinka-mat') 

const StairsPriceLabel = document.getElementById('stairs-price')
const StairsMatLabel = document.getElementById('stairs-mat') 

const TentPriceLabel = document.getElementById('tent-price')
const TentMatLabel = document.getElementById('tent-mat') 

const ChairPriceLabel = document.getElementById('chair-price')
const ChairMatLabel = document.getElementById('chair-mat') 

const canvas = document.querySelector('canvas.webgl')


// Комплектация инфо
const ChanInfo = document.getElementById('chan-info'),
PodstavkaInfo = document.getElementById('podstavka-info'),
DoorInfo = document.getElementById('door-info'),
PechkaInfo = document.getElementById('pechka-info'),
MatInfo = document.getElementById('mat-info'),
BortikInfo = document.getElementById('bortik-info'),
CapInfo = document.getElementById('cap-info'),
StairsInfo = document.getElementById('stairs-info')


// Комплектация картинки
const ChanImg = document.getElementById('img-chan'),
PodstavkaImg = document.getElementById('img-podstavka'),
DoorImg = document.getElementById('img-door'),
PechkaImg = document.getElementById('img-stove'),
MatImg = document.getElementById('img-mat'),
BortikImg= document.getElementById('img-back'),
CapImg = document.getElementById('img-top'),
StairsImg = document.getElementById('img-stairs')




let BlockSetup = 'block'
let IventMenue = 'mouseover' 

ChanSizeLabel.addEventListener(IventMenue, (event) =>
{
    SizeSetup.style.display = BlockSetup
    gsap.to(SizeSetup, { delay: 0,duration: 0.5, ease: "power3.out", opacity: 1 })

}
)

PodstavkaMatLabel.addEventListener(IventMenue, (event) =>
{
    PodstavkaSetup.style.display = BlockSetup
    gsap.to(PodstavkaSetup, { delay: 0,duration: 0.5, ease: "power3.out", opacity: 1 })
}
)

PechkaMaterialLabel.addEventListener(IventMenue, (event) =>
{
    MatSetup.style.display = BlockSetup
    gsap.to(MatSetup, { delay: 0,duration: 0.5, ease: "power3.out", opacity: 1 })
}
)


PechkaMatLabel.addEventListener(IventMenue, (event) =>
{
    PechkaSetup.style.display = BlockSetup
    gsap.to(PechkaSetup, { delay: 0,duration: 0.5, ease: "power3.out", opacity: 1 })
}
)



DoorMatLabel.addEventListener(IventMenue, (event) =>
{
    DoorsSetup.style.display = BlockSetup
    gsap.to(DoorsSetup, { delay: 0,duration: 0.5, ease: "power3.out", opacity: 1 })
}
)

SpinkaMatLabel.addEventListener(IventMenue, (event) =>
{
    SpinkaSetup.style.display = BlockSetup
    gsap.to(SpinkaSetup, { delay: 0,duration: 0.5, ease: "power3.out", opacity: 1 })
}
)

StairsMatLabel.addEventListener(IventMenue, (event) =>
{
    StairsSetup.style.display = BlockSetup
    gsap.to(StairsSetup, { delay: 0,duration: 0.5, ease: "power3.out", opacity: 1 })
}
)

TentMatLabel.addEventListener(IventMenue, (event) =>
{
    TentSetup.style.display = BlockSetup
    gsap.to(TentSetup, { delay: 0,duration: 0.5, ease: "power3.out", opacity: 1 })
}
)

ChairMatLabel.addEventListener(IventMenue, (event) =>
{
    ChairSetup.style.display = BlockSetup
    gsap.to(ChairSetup, { delay: 0,duration: 0.5, ease: "power3.out", opacity: 1 })
}
)


/**
 * Base
 */
// Debug
// const gui = new dat.GUI({
// })



/**
 * Loaders
 */


 const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
    Loader.classList.add("loader-visible")
    // setTimeout(Loader.classList.add("loader-hide"), 30000)
    // setTimeout(Loader.style.display = 'None', 3000)
    // Loader.style.display = 'none'
    
    // gsap.to(Loader, { delay: 1, x:'100vw' })
    gsap.to(Loader, { delay: 1, display:'none' })
    // gsap.to(ChermetMaterial, { duration: 1, delay: 5, opacity: 1 })
    // console.log('готово') 
    // guides.start()
    
    },
    // Progress
    (itemUrl, itemsLoaded,itemsTotal) =>
    {
        const progressRatio = itemsLoaded / itemsTotal-0.1
        loadingWater.style.transform = `scaleY(${progressRatio})`
        // console.log(itemUrl,itemsLoaded,itemsTotal)
        
        
    }
    
    )
    


//TExtures

const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)
const textureLoader = new THREE.TextureLoader(loadingManager)


const WoodColorTexture = textureLoader.load('textures/wood-map_.jpg')
WoodColorTexture.generateMipmaps = true
//  WoodColorTexture.minFilter = THREE.LinearFilter  если включить, то будет рябь 
//  WoodColorTexture.magFilter = THREE.LinearFilter


const TermoFaceColorTexture = textureLoader.load('textures/termo.jpg')

const WoodNormalTexture = textureLoader.load('textures/wood-normal.jpg')
const TentNormalTexture = textureLoader.load('textures/banner_normal.jpg')

WoodColorTexture.encoding = THREE.sRGBEncoding
TermoFaceColorTexture.encoding = THREE.sRGBEncoding



//MAterials

const ElitMaterial = new THREE.MeshStandardMaterial({ 
    color:'#170704',
    metalness: 0.6,
    side:2,
    roughness:0.9,
     envMapIntensity:10,
     name:'elitMaterial'
  
})

const NerzhMaterial = new THREE.MeshStandardMaterial({ 
    color:'#030303',
    metalness: 1,
    side:2,
    roughness:0.5,
    envMapIntensity:2,
    name:'нержавейка'
  
})


const ChermetMaterial = new THREE.MeshStandardMaterial({ 
    color:'#030303',
    metalness: 1,
    side:2,
    roughness:0.5,
    name:'железо'
})



const NerzhMaterialPodstavka = new THREE.MeshStandardMaterial({ 
    color:'#030303',
    metalness: 1,
    side:2,
    roughness:0,
    envMapIntensity:2,
    name:'нержавейка'
})

const ChermetMaterialPodstavka = new THREE.MeshStandardMaterial({ 
    color: '#030303',
    metalness: 1,
    roughness:0.6
})



const GlassMaterial = new THREE.MeshStandardMaterial({ 
    color: 'red',
    transparent: true,
    opacity: 0.5,
    side:2,
    metalness: 1,
    roughness :0.1
  
})

const TentMaterial = new THREE.MeshStandardMaterial({ 
    color: 'red', 
    normalMap:TentNormalTexture,
    metalness: 0.6,
    roughness :0.4,
    transparent: true,
    opacity: 0,
    side:2
    

  
})


const TermoFaceMaterial = new THREE.MeshStandardMaterial({ 
    color:'white',
    side:2,
    // doubleSided:true
    map:TermoFaceColorTexture,
    emissiveMap:TermoFaceColorTexture,
    emissive:'white'

})


const WoodTentMaterial = new THREE.MeshStandardMaterial({ 
    map:WoodColorTexture,
    metalness: 0.1,
    roughness :0.4,
    normalMap:WoodNormalTexture,
    transparent: true,
    opacity: 0,
    side:2,
    
})
WoodTentMaterial.normalScale.set(0.5, 0.5)
WoodTentMaterial.map.offset.z = 1.4 


const TentChromMaterial = new THREE.MeshStandardMaterial({ 
    color: 'gray', 
    side:2,
    transparent: true,
    opacity: 0,
    metalness: 1,
    roughness: 0.1
})

const TentRopeMaterial = new THREE.MeshStandardMaterial({ 
    color: 'white', 
    transparent: true,
    opacity: 0
})


const WoodMaterial = new THREE.MeshStandardMaterial({ 
    map:WoodColorTexture,
    metalness: 0.1,
    roughness :0.4,
    normalMap:WoodNormalTexture
})
WoodMaterial.normalScale.set(0.5, 0.5)
WoodMaterial.map.offset.z = 1.4 


const ChromMaterials = new THREE.MeshStandardMaterial({ 
    color: 'gray', 
    side:2  
})
ChromMaterials.metalness = 1
ChromMaterials.roughness = 0.1


let chermet = 'Черн.мет'

// Размер чанов
let ChanSmall = {name:['chasha','termo-body'],scale:0.0725,price:115000,mat:ChromMaterials,text:'Чан 1600x1600 на 2-3 чел. из нержавеющей стали AISI 304, толщиной стенок 2мм и толщиной дна 3мм',size:'1600x1600',
PodstavkaChern:11000,PodstavkaNerzh:22000,PechkaChern:55000,PechkaNerzh:76000,img:'./images/chasha-small.jpg'
}
let ChanMid = {name:['chasha','termo-body'],scale:0.086,price:120000,mat:ChromMaterials,text:'Чан 1900x1900 на 4-6 чел. из нержавеющей стали AISI 304, толщиной стенок 2мм и толщиной дна 3мм',size:'1900x1900',
PodstavkaChern:12000,PodstavkaNerzh:24000,PechkaChern:57000,PechkaNerzh:79000,img:'./images/chasha-mid.jpg'
}
let ChanBig = {name:['chasha','termo-body'],scale:0.1,price:170000,mat:ChromMaterials,text:'Чан 2200x2200 на 6-9 чел. из нержавеющей стали AISI 304, толщиной стенок и дна 3мм',size:'2200x2200',
PodstavkaChern:14000,PodstavkaNerzh:28000,PechkaChern:65000,PechkaNerzh:95000,img:'./images/chasha-big.jpg'
}
let ChanElit = {name:['chasha','termo-body','table'],scale:0.1,price:182000,mat:ElitMaterial,text:'Чан элитный 2200x2200 на 6-9 чел. из нержавеющей стали AISI 304, толщиной 3мм, и внешним столиком',size:'2200x2200',
PodstavkaChern:14000,PodstavkaNerzh:28000,PechkaChern:65000,PechkaNerzh:95000,PechkaElit:95000,img:'./images/chasha-elit.jpg'}
let ChanElitMid = {name:['chasha','termo-body','table'],scale:0.086,price:132000,mat:ElitMaterial,text:'Чан элитный 1900x1900 на 4-6 чел. из нержавеющей стали AISI 304, толщиной 3мм, и внешним столиком',size:'1900x1900',
PodstavkaChern:12000,PodstavkaNerzh:24000,PechkaChern:57000,PechkaNerzh:79000,PechkaElit:79000,img:'./images/chasha-mid.jpg' }
let ChanElitSmall = {name:['chasha','termo-body','table'],scale:0.0725,price:127000,mat:ElitMaterial,text:'Чан элитный 1600x1600 на 2-3 чел. из нержавеющей стали AISI 304, толщиной 3мм, и внешним столиком',size:'1600x1600',
PodstavkaChern:11000,PodstavkaNerzh:22000,PechkaChern:55000,PechkaNerzh:76000,PechkaElit:76000 ,img:'./images/chasha-small.jpg'}


// Типы подставок
let PodstavkaNone = {name:'',price:0,mat:NerzhMaterialPodstavka,text:'',label:'без подставки',img:'./images/stairz-none.jpg'}
let PodstavkaNerzh = {name:'pechka-none',price:123000,mat:NerzhMaterialPodstavka,text:'Подставка - профильная труба из нержавеющей стали AISI 304',label:'Нержавейка',img:'./images/podstavka.jpg'}
let PodstavkaChermet = {name:'pechka-none',price:20000,mat:ChermetMaterialPodstavka,text:'Подставка - профильная труба из чёрного металла',label:chermet,img:'./images/podstavka.jpg'}


// Типы печки
let PechkaElit = {name:'elit-pechka',price:5000,mat:ElitMaterial,text:'',label:'Закругленная',img:'./images/pechka-elit.jpg'}
let PechkaCurve = {name:'elit-pechka',price:5000,mat:NerzhMaterial,text:'',label:'Закругленная',img:'./images/pechka-curve.jpg'}
let PechkaLine = {name:'pechka-body',price:0,mat:ChermetMaterial,text:'',label:'Прямая',img:'./images/pechka-line.jpg'}
let PechkaNone = {name:'',price:0,mat:PodstavkaChermet.mat,text:'Вариант без печки может производить более экзотическое впечатление, но не очень эффективен с точки зрения подогрева воды.',label:'Без печки',img:'./images/podstavka.jpg'}



// Тип дверци
let DoorGluh = {name:'pechka-gluh',price:0,mat:'',text:'Глухая дверца',label:'Глухая',img:'./images/door-gluh.jpg'}
let DoorGlass = {name:'pechka-glass',price:15000,mat:'',text:'Чугунная дверца с огнеупорным стеклом',label:'Стекло',img:'./images/door-glass.jpg'}
let DoorElit = {name:'pechka-glass',price:15000,mat:ElitMaterial,text:'Чугунная дверца с огнеупорным стеклом',label:'Стекло',img:'./images/door-elit.jpg'}
let DoorNone = {name:'door-none',price:0,mat:'',text:'Печки нет, значит за дверцу вы не платите',label:'Без дверцы',img:'./images/door-none.jpg'}


// Материал печек
// let PechkaPrem = {name:'pechka-body',price:52000,mat:'ChermetMaterial',text:'Печка из огнеупорно-окрашенного чермета',label:chermet}
let ElitMat = {name:'',price:152000,mat:ElitMaterial,text:'нержавеющей стали AISI 304 с дымоходом из нержавеющей стали',label:'Нержавейка',img:'./images/mat-elit.jpg'}
let NerzhMat = {name:'',price:152000,mat:NerzhMaterial,text:'нержавеющей стали AISI 304 с дымоходом из нержавеющей стали',label:'Нержавейка',img:'./images/mat-nerzh.jpg'}
let ChermetMat = {name:'',price:52000,mat:ChermetMaterial,text:'чёрного металла с дымоходом из нержавеющей стали',label:chermet,img:'./images/mat-chermet.jpg'} /* поменяли на ChermetMaterial  */
let MatNone = {name:'',price:0,mat:'',text:'Печки нет, значит за материал вы не платите',label:'Без печки',img:'./images/mat-none.jpg'} /* поменяли на ChermetMaterial  */


// Типы спинок
let SpinkaNerzhWood = {name:'SpinkaNerzhWood',price:0,mat:WoodMaterial,text:'Вы опираетесь спиной прямо на чан — бортик теплый и комфортный — в двух вариантах:',label:'Без бортика',img:'./images/spinka-nerzh.jpg'}
let SpinkaWood = {name:'SpinkaWood',price:5000,mat:WoodMaterial,text:'Бортик из лиственницы',label:'Лиственница',img:'./images/spinka-wood.jpg'}

// Типы лестниц
let StairsPloshadka = {name:'StairsPlochadka',price:17000,mat:ChermetMaterialPodstavka,text:'Супер-безопасная лестница с площадкой перед чаном, сварная из черного металла и ступенями из лиственницы.',label:'Безопасная',img:'./images/stairz-ploshadka.jpg'}
let StairsNerzh = {name:'StairzNerzh',price:29000,mat:ChermetMaterialPodstavka,text:'Стильная лестница - красивая, с отделкой, с элементами из нержавейки, без площадки перед чаном',label:'Стильная',img:'./images/stairz-nerzh.jpg'}
let StairsNone = {name:'',price:0,mat:ChermetMaterialPodstavka,text:'Вариант без лестницы сэкономит вам деньги, но вам надо будет подумать как забираться в чан',label:'Без лестницы',img:'./images/stairz-none.jpg'}

// Типы тента
let Tent = {name:'Tent',price:0,mat:TentMaterial,text:'Тент из материала со специальной пропиткой',label:'Тент',img:'./images/tent-wood.jpg'}
let WoodTent = {name:'WoodTent',price:29000,mat:WoodMaterial,text:'Крышка из лиственницы на нержавеющем каркасе',label:'Лиственница',img:'./images/tent-wood-1.jpg'}
let NoneTent = {name:'NoneTent',price:0,mat:WoodMaterial,text:'Без тента',label:'',img:'./images/stairz-none.jpg'}

// Стульчик
let Chair = {name:'chair',price:10000,mat:WoodMaterial,text:'детский стульчик',label:'Есть',img:'./images/stairz-none.jpg'}
let ChairNone = {name:'none',price:0,mat:WoodMaterial,text:'',label:'Нет',img:'./images/stairz-none.jpg'}



//Массив с частями чанов по материалам
let AllMat = {
'WoodMaterial':['table-wood','SpinkaWood001','SpinkaWood002','SpinkaWood003','SpinkaWood','WoodSit','wood_handle_pech','StairzNerzhWood','StairsPlochadka','SpinkaNerzhWood','firewood','chair'],
'GlassMaterial': ['pechka_door_glass'],
'ChromMaterials':['table','chair-stand','elit-cock','Elit-tube','drain-cock','boltiki','boltiki2','p-stairs-boltiki','StairzNerzzhBoltiki','PechkaTube','SpinkaNerzh','StairzNerzhPerila','termo-body'],
'ChermetMaterialPodstavka':['drain-connect','p-stairs-opora','termo-arrow','StairzNerzh','pechka-body'],    /*добавил pechka-body, чтобы на главной был материал   */
'WoodTentMaterial':['WoodTent'],
'TentChromMaterial':['WoodTentBoltiki','luvers'],
'TentRopeMaterial':['TentRope'],
'TermoFaceMaterial':['termo-face'],
'TentMaterial':['Tent'],
'NerzhMaterial':['pechka-body']
}


const dracoLoader = new DRACOLoader(loadingManager)
dracoLoader.setDecoderPath('/draco/')

const gltfLoader = new GLTFLoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)
const scene = new THREE.Scene()



let ik = 0;

//Ищет совпадения по ключу
function GetCahn(object, find, matchess = []) {
            for(let value of Object.values(object)) {
               
                    matchess.push(value[find])  
                }   
    return matchess
}  


function ChanCreate(ChanSize, ChanBottom, ChanStove, ChanDoor, ChanMat, ChanBack, ChanStair, ChanTop, ChanChair ) {
    this.ChanSize = ChanSize
    this.ChanBottom = ChanBottom
    this.ChanStove = ChanStove
    this.ChanDoor = ChanDoor
    this.ChanMat = ChanMat
    this.ChanBack = ChanBack
    this.ChanStair = ChanStair
    this.ChanTop = ChanTop
    this.ChanChair = ChanChair
  }

//   Убираем историю нажатий
  function  RemoveClass(exclude,items) {
    for(let item of items){
    if (item != exclude ) {
    item.classList.remove("buttonActive")}

    }}


const updateAllMaterials = () =>
        {
            scene.traverse((child) =>
            {
                if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
                {
                    child.material.envMapIntensity = 1
                    child.material.needsUpdate = true
                    child.castShadow = true
                    child.receiveShadow = true
                    child.material.doubleSided = true
                    if ( child.name === Tent.name) {
                        child.castShadow = false
                        // child.receiveShadow = false
                    }

                    if ( child.name === WoodTent.name) {
                        child.castShadow = false
                    }

                    if ( child.name === "luvers") {
                        child.castShadow = false
                    }

                    if ( child.name === "TentRope") {
                        child.castShadow = false
                    }

                    if ( child.name === 'pechka-body') {
                        child.material.envMapIntensity = 1
                        

                    }
     
                }
            })
        }

/**
 * Points of interest
 */
 const raycaster = new THREE.Raycaster()
 const raycasterObj = new THREE.Raycaster()


//  проверяет страницу на главную

 let mainPage = function(mainP = false){
 if (document.location.pathname[document.location.pathname.length-1] === "/"|| document.location.pathname.match("index.html")) 
 {
    mainP = true   
 }
 else {
    mainP = false 
 }
 return mainP 
 }

 let SmokeVertex = new THREE.Vector3( 0, 0, 0 );

     /**
     * Костер
     */
    // Geometry
    const firefliesGeometry = new THREE.BufferGeometry()
    let firefliesCount = 160
    const positionArray = new Float32Array(firefliesCount * 3)
    const scaleArray = new Float32Array(firefliesCount)

    for(let i = 0; i < firefliesCount; i++)
    {
        positionArray[i * 3 + 0] = (Math.random() - 0.5) * 0.4
        positionArray[i * 3 + 1] = Math.random() * 0.1
        positionArray[i * 3 + 2] = (Math.random() - 0.5) * 0.6

        scaleArray[i] = Math.random()
    }

    firefliesGeometry.setAttribute('position', new THREE.BufferAttribute(positionArray, 3))
    firefliesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1))

    // Material
    const firefliesMaterial = new THREE.ShaderMaterial({
        uniforms:
        {
            uTime: { value: 0 },
            uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
            uSize: { value: 250 }
        },
        vertexShader: firefliesVertexShader,
        fragmentShader: firefliesFragmentShader,
         transparent: true,
         blending: THREE.AdditiveBlending,
        depthWrite: false
    })

    const fireflies = new THREE.Points(firefliesGeometry, firefliesMaterial)
    fireflies.renderOrder = 0
    scene.add(fireflies)


    
     /**
     * Дым
     */
    // Geometry
    const  SmokeGeometry = new THREE.BufferGeometry()
    let SmokeCount = 70
    const SmokePositionArray = new Float32Array(SmokeCount * 3)
    const SmokeScaleArray = new Float32Array(SmokeCount)

    for(let i = 0; i < SmokeCount; i++)
    {
        SmokePositionArray[i * 3 + 0] = (Math.random() - 0.5) * 0.1
        SmokePositionArray[i * 3 + 1] = (Math.random()- 0.1) * 2
        SmokePositionArray[i * 3 + 2] = (Math.random() - 0.5) * 0.1

        SmokeScaleArray[i] = Math.random()
    }

    SmokeGeometry.setAttribute('position', new THREE.BufferAttribute(SmokePositionArray, 3))
    SmokeGeometry.setAttribute('aScale', new THREE.BufferAttribute(SmokeScaleArray, 1))

    // Material
    const SmokeMaterial = new THREE.ShaderMaterial({
        uniforms:
        {
            uTime: { value: 0 },
            uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
            uSize: { value: 950 }
        },
        vertexShader: SmokeVertexShader,
        fragmentShader: SmokeFragmentShader,
         transparent: true,
         blending: THREE.AdditiveBlending,
        depthWrite: false
    })

    const SmokeP = new THREE.Points(SmokeGeometry, SmokeMaterial)
    SmokeP.renderOrder = 0


    /**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

let PropKoeff = sizes.height / sizes.width  

    /**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)

// console.log(PropKoeff)
let zoom = 3.1
if(mainPage())
{
    zoom = 2.2
}
else {
    zoom = 3.1  
}



camera.position.set(0, 1.2, zoom+PropKoeff)
scene.add(camera)
const mouse = new THREE.Vector2()



let points = [

]



        
     gltfLoader.load(
    './models/chan/chan_draco.glb',
    (gltf) =>
    {

        // Сетапер для чана, в зависимости от урла

        let NewChan ={}

        if (mainPage()) { 
            
             NewChan = new ChanCreate(ChanElit, PodstavkaChermet, PechkaElit, DoorElit, ElitMat, SpinkaNerzhWood, StairsNerzh, WoodTent, ChairNone)  
            //  NewChan = new ChanCreate(ChanMid, PodstavkaChermet, PechkaCurve, DoorGlass, PechkaNerzh, SpinkaNerzhWood, StairsPloshadka, Tent, ChairNone)

             WoodTentMaterial.opacity=1
             TentMaterial.opacity=1
             TentChromMaterial.opacity=1
             TentRopeMaterial.opacity=1
              
         }

        if (document.location.pathname.match("construktor.html")) { 
            NewChan = new ChanCreate(ChanMid, PodstavkaChermet, PechkaCurve, DoorGlass, ChermetMat, SpinkaNerzhWood, StairsPloshadka, Tent, ChairNone)
        }

        if (document.location.pathname.match("golden.html")) { 
            NewChan = new ChanCreate(ChanMid, PodstavkaChermet, PechkaLine, DoorGluh, ChermetMat, SpinkaNerzhWood, StairsPloshadka, Tent, Chair)
        }

        if (document.location.pathname.match("premium.html")) { 
            NewChan = new ChanCreate(ChanMid, PodstavkaChermet, PechkaCurve, DoorGlass, NerzhMat, SpinkaWood, StairsNerzh, WoodTent, Chair)
        }

        if (document.location.pathname.match("compact.html")) { 
            NewChan = new ChanCreate(ChanSmall, PodstavkaChermet, PechkaLine, DoorGluh, ChermetMat, SpinkaNerzhWood, StairsNone, Tent, ChairNone)
        }

        if (document.location.pathname.match("super.html")) { 
            NewChan = new ChanCreate(ChanElit, PodstavkaNerzh, PechkaElit, DoorElit, ElitMat, SpinkaNerzhWood, StairsPloshadka, WoodTent, Chair)
        }
        
 
        const TentMesh = gltf.scene.children.find((child) => child.name === WoodTent.name)
        const Chasha = gltf.scene.children.find((child) => child.name === ChanBig.name[0])
        const Smoke = gltf.scene.children.find((child) => child.name === 'smoke')
        // const WoodTentBoltiki = gltf.scene.children.find((child) => child.name === 'WoodTentBoltiki')
        let TentPropitka = gltf.scene.children.find((child) => child.name === Tent.name)
        let Termo = gltf.scene.children.find((child) => child.name === 'termo-body') 
        let DoorGluhh = gltf.scene.children.find((child) => child.name === 'pechka-gluh') 
        let DoorGlasss = gltf.scene.children.find((child) => child.name === 'pechka-glass') 
        let Podstavka = gltf.scene.children.find((child) => child.name === 'pechka-none') 
        let PechkaLines = gltf.scene.children.find((child) => child.name === 'pechka-body') 
        let PechkaElits = gltf.scene.children.find((child) => child.name === 'elit-pechka') 
        const firewood = gltf.scene.children.find((child) => child.name ==='firewood')


        TentMesh.renderOrder = 5
        TentPropitka.renderOrder = 5
        TentPropitka.receiveShadow = false

        // gltf.scene.traverse((child) =>
        // { console.log(child.name, child) })


         // вода
        const radiusTop = 1.04;
        const radiusBottom = 0;
        const height = 0;
        const radialSegments = 8

        const geometry = new THREE.CylinderBufferGeometry(radiusTop, radiusBottom, height, radialSegments)

        // const WaterMesh = new THREE.Mesh(geometry, WoodMaterial)

        geometry.name = 'water'

        let water
        const params = {
            color: '#394c6b',
            scale: 0.05,
            flowX: 0.5,
            flowY:-0.5
        }
        water = new Water( geometry, {
            color: params.color,
            scale: params.scale,
            flowDirection: new THREE.Vector2( params.flowX, params.flowY ),
            textureWidth: 1024,
            textureHeight: 1024,
            
 
        } )
        water.name = 'MeshWater'
        water.position.y = 14
		water.rotation.y = Math.PI * + 0.125
        water.scale.set(10,10,10)
            
        water.renderOrder = 1
         // конец воды
         gltf.scene.add(water) 
         
        let defualtScale = NewChan.ChanSize.scale
        
        gltf.scene.scale.set(defualtScale,defualtScale,defualtScale)


        // Устанваливаем материал (формируем массив с названиями материалов)
        let ArrMat = {}
        for (let item of Object.values(NewChan) ) {
            if (typeof(item.name) === 'object') {
             for (let o in item.name){
            ArrMat[item.name[o]] =  item.mat
             }
            }
             else
             ArrMat[item.name] = item.mat
        }
         
         for (let item in ArrMat) {
            gltf.scene.traverse((child) => { 
                if (child.name === item) {
                    child.material = eval(ArrMat[item])
                }
                 })
         }
         



        // Устанваливаем материал для вложенных элементов
         for (let mat of Object.keys(AllMat)) {
           for (let obj of AllMat[mat] ) {
            gltf.scene.traverse((child) => { 
                if (child.name === obj) {
                    child.material = eval(mat)
                }

                if (child.name === 'WoodTentBoltiki') {
                    child.renderOrder = 5
                }

                if (child.name === 'pechka-body') {
                    child.renderOrder = 1
                }
                 })
           }
         }

        // номер вершины 59б 69 83
        let indx = {point:86}  

    

        // Сетапер чана ________________________________________________________________________________________________________________
    let ChanType = function (obj) {

        // Меняем цену подставки и печки в зависимости от размера
        if(NewChan.ChanBottom.mat===ChermetMaterialPodstavka) {
        NewChan.ChanBottom.price=NewChan.ChanSize.PodstavkaChern}

        if(NewChan.ChanBottom.mat===NerzhMaterialPodstavka) { 
        NewChan.ChanBottom.price=NewChan.ChanSize.PodstavkaNerzh}

        if(NewChan.ChanMat.mat===NerzhMaterial) {
        NewChan.ChanMat.price=NewChan.ChanSize.PechkaNerzh}

        if(NewChan.ChanMat.mat===ChermetMaterial) {
        NewChan.ChanMat.price=NewChan.ChanSize.PechkaChern}

        if(NewChan.ChanMat.mat===ElitMaterial) {
        NewChan.ChanMat.price=NewChan.ChanSize.PechkaElit}
        
        if(NewChan.ChanMat.mat===''){
        NewChan.ChanMat.price=0}

 



        let material = NewChan.ChanMat.text
        let doorString = material.split(' с ')
        
        // DoorElit.text = `Дверца со стеклом из ${doorString[0]}, чтобы наблюдать за языками пламени вашего банного храма`
        DoorGluh.text = `Глухая дверца из ${doorString[0]}`
        // DoorGlass.text = `Дверца со стеклом, из ${doorString[0]}, чтобы наблюдать за языками пламени вашего банного храма`
        PechkaElit.text = `Печка со скругленными краями из ${material}`
        PechkaCurve.text = `Печка со скругленными краями из ${material} `
        PechkaLine.text = `Печка с прямыми краями из ${material}`
  

        gltf.scene.children.find((child) => 
        {  
            let ChanNames = GetCahn(NewChan,'name')
            // console.log(ChanNames)

            if (ChanNames.flat(Infinity).includes(child.name)) {                
                child.visible = true

                            }
            else {
                child.visible = false}

                if (mainPage()) { 
                }
        }
        ) 

        if (NewChan.ChanSize.text === ChanElit.text){
            Chasha.material = ElitMaterial
        } else{
            Chasha.material = ChromMaterials  
        }


        if (NewChan.ChanStove.name === PechkaCurve.name) {

            DoorGlasss.position.z = -2
            DoorGluhh.position.z = -2
            DoorGlasss.children[0].renderOrder=2
            DoorGluhh.renderOrder=2
            Podstavka.scale.set(0.95,0.95,0.95)

   
       }
       else { 
        DoorGlasss.position.z = 0
        DoorGluhh.position.z = 0
        Podstavka.scale.set(1,1,1) 
    }

        

        // Устанавливаем размер чана
        let scale = NewChan.ChanSize.scale
        gltf.scene.scale.set(scale,0.1,scale)
        let Stairz = gltf.scene.children.find((child) => child.name === NewChan.ChanStair.name)  

            // термометр
        if (Termo) { 
            Termo.scale.y = 1+(0.1-scale)*10
        }    

        if (DoorGluhh) { 
            DoorGluhh.scale.x = 1+(0.1-scale)*10
        }  

        if (DoorGlasss) { 
            DoorGlasss.scale.x = 1+(0.1-scale)*10
        }  

        if (Stairz) { 
            Stairz.scale.set(1+(0.1-scale)*10,1+(0.1-scale)*10,1)                  
        }

        firewood.scale.set(1+(0.1-scale)*10,1+(0.1-scale)*10,1)

        // Конец размера

        // Дым из трубы меняем позицию
        let posSmoke = Smoke.geometry.attributes.position; 
        Smoke.updateWorldMatrix(true)
        SmokeVertex = new THREE.Vector3( posSmoke.getX(0), posSmoke.getY(0), posSmoke.getZ(0) );
        Smoke.localToWorld(SmokeVertex)
        SmokeP.position.set(SmokeVertex.x,SmokeVertex.y,SmokeVertex.z)

        if (NewChan.ChanStove.name === '') {
            DoorGlasss.visible = false
            DoorGluhh.visible = false
            NewChan.ChanDoor = DoorNone
            NewChan.ChanMat = MatNone
            DoorBlock.style.display = 'none'
            MatBlock.style.display = 'none'
            scene.remove(SmokeP)
        } 

        else {            
            DoorBlock.style.display = 'block' 
            MatBlock.style.display = 'block' 
            scene.add(SmokeP)     
        }


       let TotalPrise = GetCahn(NewChan,'price').reduce((partialSum, a) => partialSum + a, 0)

    

        // Установка лейблов для таблици
        Price.innerHTML = TotalPrise
        ChanPriceLabel.innerText = NewChan.ChanSize.price +' р'
        ChanSizeLabel.innerText = NewChan.ChanSize.size

        PodstavkaPriceLabel.innerText = NewChan.ChanBottom.price +' р'
        PodstavkaMatLabel.innerText = NewChan.ChanBottom.label 

        PechkaPriceLabel.innerText = NewChan.ChanStove.price +' р' 
        PechkaMatLabel.innerText = NewChan.ChanStove.label 

        PechkaMatPriceLabel.innerText = NewChan.ChanMat.price +' р' 
        PechkaMaterialLabel.innerText = NewChan.ChanMat.label 

        SpinkaPriceLabel.innerText = NewChan.ChanBack.price +' р'
        SpinkaMatLabel.innerText = NewChan.ChanBack.label 

        StairsPriceLabel.innerText = NewChan.ChanStair.price +' р' 
        StairsMatLabel.innerText = NewChan.ChanStair.label 

        TentPriceLabel.innerText = NewChan.ChanTop.price +' р' 
        TentMatLabel.innerText = NewChan.ChanTop.label 

        ChairPriceLabel.innerText = NewChan.ChanChair.price +' р' 
        ChairMatLabel.innerText = NewChan.ChanChair.label 


        gsap.to(TentSetup, { delay: 0.3,duration: 0.1, opacity: 0 })
        gsap.to(TentSetup, { delay: 0.4, display:'none' })

 
        gsap.to(StairsSetup, { delay: 0.3,duration: 0.1, opacity: 0 })
        gsap.to(StairsSetup, { delay: 0.4, display:'none' })


        gsap.to(SpinkaSetup, { delay: 0.3,duration: 0.1, opacity: 0 })
        gsap.to(SpinkaSetup, { delay: 0.4, display:'none' })

        gsap.to(SizeSetup, { delay: 0.3,duration: 0.1, opacity: 0 })
        gsap.to(SizeSetup, { delay: 0.4, display:'none' })

        gsap.to(PodstavkaSetup, { delay: 0.3,duration: 0.1, opacity: 0 })
        gsap.to(PodstavkaSetup, { delay: 0.4, display:'none' })
       
        gsap.to(PechkaSetup, { delay: 0.3,duration: 0.1, opacity: 0 })
        gsap.to(PechkaSetup, { delay: 0.4, display:'none' })

        gsap.to(MatSetup, { delay: 0.3,duration: 0.1, opacity: 0 })
        gsap.to(MatSetup, { delay: 0.4, display:'none' })
        
        gsap.to(DoorsSetup, { delay: 0.3,duration: 0.1, opacity: 0 })
        gsap.to(DoorsSetup, { delay: 0.4, display:'none' })

        gsap.to(ChairSetup, { delay: 0.3,duration: 0.1, opacity: 0 })
        gsap.to(ChairSetup, { delay: 0.4, display:'none' })
        
        DoorPriceLabel.innerText = NewChan.ChanDoor.price 
        DoorMatLabel.innerText = NewChan.ChanDoor.label 
        
        PechkaLines.material = NewChan.ChanMat.mat
        PechkaElits.material = NewChan.ChanMat.mat
 
        DoorGluhh.material = NewChan.ChanMat.mat
        DoorGlasss.material = NewChan.ChanMat.mat


        // Устанавливаем текст для инфо блоков таблици

        if(ChanInfo)  { 
        ChanInfo.innerText = NewChan.ChanSize.text
        PodstavkaInfo.innerText = NewChan.ChanBottom.text
        DoorInfo.innerText = NewChan.ChanDoor.text
        PechkaInfo.innerText = NewChan.ChanStove.text
        MatInfo.innerText = NewChan.ChanMat.text
        BortikInfo.innerText = NewChan.ChanBack.text
        CapInfo.innerText = NewChan.ChanTop.text
        StairsInfo.innerText = NewChan.ChanStair.text

        ChanImg.src = NewChan.ChanSize.img
        PodstavkaImg.src = NewChan.ChanBottom.img
        DoorImg.src = NewChan.ChanDoor.img
        PechkaImg.src = NewChan.ChanStove.img
        MatImg.src = NewChan.ChanMat.img
        BortikImg.src = NewChan.ChanBack.img
        CapImg.src = NewChan.ChanTop.img
        StairsImg.src = NewChan.ChanStair.img


    }



        TentPropitka.castShadow = false
        TentMesh.castShadow = false

        if (NewChan.ChanBack.name === SpinkaNerzhWood.name) {
            TentMesh.position.y = 0.48
        } else {
        TentMesh.position.y = 0.04}

        water.visible = true
        firewood.visible = true
        // console.log('click',NewChan.ChanSize.text)

        document.querySelectorAll('.point').forEach(e => e.remove())

        let ii = 0
        let kk=0
        


         // Устанавливаем точки

        points = [

        ]


        for (let item of Object.values(NewChan) ) {
            
            let CurentObj = gltf.scene.children.find((child) => child.name === item.name)
            try {              
                if (CurentObj) {
                    CurentObj = gltf.scene.children.find((child) => child.name === item.name)
                 }
    
                 else { CurentObj = gltf.scene.children.find((child) => child.name === item.name[0])}

                 let pos = CurentObj.geometry.attributes.position; 
                 // let idx = CurentObj.geometry.index.array.length
                 CurentObj.updateWorldMatrix(true)
                 let vertex = new THREE.Vector3( pos.getX(indx.point), pos.getY(indx.point), pos.getZ(indx.point) );

                 CurentObj.localToWorld(vertex)
                //  CurentObj.updateMatrixWorld(true)
                // console.log(Object.values(NewChan).length)
                // Ancor.textContent = vertex.x

                 let new_point = document.createElement('div')
                 new_point.className = `point point-${kk} visible`
                 let label = document.createElement('div')
                 label.className = 'label'
                 label.textContent = ''
                 new_point.append(label)
     
                 let text = document.createElement('div')
                 text.className = 'text'
                 text.textContent = item.text
                 
                // добавляет картинку во всплывашку при наведении на точку
                //  if(item.src){
                //  let imgg = document.createElement('img')
                //  imgg.src = item.src
                //  imgg.style = 'display:block;'
                //  text.appendChild(imgg)
                // }

                 new_point.append(text)
                 CanvasDiv.append(new_point)


                 points.push({   
                 position: new THREE.Vector3(vertex.x,vertex.y,vertex.z),
                 element: document.querySelector(`.point-${kk}`)
                 })

                 ii += 0.5
                 kk += 1
              } catch (err) {
              }      
            
        }
    

        let Points = document.querySelectorAll('.point')
        
        Points.forEach(Button => Button.addEventListener(
            'mouseover',
            function() { 
            controls.autoRotate = false
             intervals(controls.autoRotate) 
            
                }, false
        )) 
    

        Points.forEach(Button => Button.addEventListener(
            'mouseout',
            function() { 
            controls.autoRotate = true            
            intervals(controls.autoRotate)       
                },
            false
        ))
    
        // для мобилки

        Points.forEach(Button => Button.addEventListener(
            'touchstart',
            function() { 
                controls.autoRotate = false 
                    
                },
            false
        ))

        
        canvas.addEventListener('touchstart', (event) =>
        {
            controls.autoRotate = true
                
        }
        )

    }

// _________________________________________________________________________________________________________________________________________



// Вариации чановы для главной
let GraphChan = new ChanCreate(ChanBig, PodstavkaChermet, PechkaLine, DoorGlass, NerzhMat, SpinkaNerzhWood, StairsPloshadka, Tent, ChairNone)
let PremChan = new ChanCreate(ChanMid, PodstavkaChermet, PechkaCurve, DoorGluh, ChermetMat, SpinkaWood, StairsPloshadka, NoneTent, Chair)
let ElitChan = new ChanCreate(ChanElit, PodstavkaNerzh, PechkaElit, DoorElit, ElitMat, SpinkaNerzhWood, StairsNerzh, NoneTent, Chair)
let CompaktChan = new ChanCreate(ChanSmall, PodstavkaChermet, PechkaNone, DoorNone, MatNone, SpinkaWood, StairsNone, WoodTent, ChairNone)

let Chans = [GraphChan,CompaktChan,PremChan,ElitChan]
// let Chans = [PremChan]


ChanType(NewChan)

// останавливаем вращение при наведении на точки
let int = 0
  function getInt(max) {
    // let int = 0
    int += 1
        if (int===max){
        int=0
        }  
    return int
  }

  //   Меняем чаны на главной

    let timerId = setTimeout(
        function tick() {
        if (mainPage()) { 
        NewChan = Chans[getInt(Chans.length)]
        ChanType(NewChan)}
        timerId = setTimeout(tick, 3000)
    }, 3000
    )

    // функция остановки смены чанов
        let intervals = function (trig) {
        if(trig === true) {
            timerId = setTimeout(
                function tick() {
                if (mainPage()) { 
                NewChan = Chans[getInt(Chans.length)]
                ChanType(NewChan)}
                timerId = setTimeout(tick, 3000)
            }, 3000
            )
        } else {
            clearTimeout(timerId);
        }       
        }




        // Button event - размер чана
        ButtonsSize.forEach(Button => Button.addEventListener(
            'click',
            function() { 
                NewChan.ChanSize = eval(this.getAttribute('name'))

                this.classList.add("buttonActive")
                RemoveClass(Button,ButtonsSize)
                ChanType(NewChan)         
            },
            false
        ))


        
        // Button event - тип подставки
        ButtonsPodstavka.forEach(Button => Button.addEventListener(
            'click',
            function() {         
                
              /**
             * Animate
             */

            ChermetMaterial.transparent = true
            NerzhMaterial.transparent = true
            
            gsap.to(ChermetMaterial, { duration: 1, delay: 0, opacity: 0.2 })
            gsap.to(NerzhMaterial, { duration: 1, delay: 0, opacity: 0.2 })
                
                
                    gsap.to(ChermetMaterial, { duration: 1, delay: 5, opacity: 1 })
                    gsap.to(NerzhMaterial, { duration: 1, delay: 5, opacity: 1 })
                    gsap.to(ChermetMaterial, { duration: 1, delay: 5, transparent: false })
                    gsap.to(NerzhMaterial, { duration: 1, delay: 5, transparent: false })
                    

                NewChan.ChanBottom = eval(this.getAttribute('name'))

                gltf.scene.traverse((child) =>
                {  

                   if (NewChan.ChanBottom.name.includes(child.name))  
                   {   
                    // console.log(gltf.scene)
                         child.material =  eval(NewChan.ChanBottom.mat)
                   }             
               })

                this.classList.add("buttonActive")
                RemoveClass(Button,ButtonsPodstavka)
                ChanType(NewChan)
                

                },
            false
        ))

  


        // Button event - тип печки
        ButtonsPechka.forEach(Button => Button.addEventListener(
            'click',
            function() { 
                let DoorsActive = document.querySelectorAll('.button-doors.buttonActive')
                let CahnMatActive = document.querySelectorAll('.button-mat.buttonActive')

                NewChan.ChanStove = eval(this.getAttribute('name'))
                NewChan.ChanMat = eval(CahnMatActive[0].getAttribute('name'))
                NewChan.ChanDoor = eval(DoorsActive[0].getAttribute('name'))


                this.classList.add("buttonActive")
                RemoveClass(Button,ButtonsPechka)
                ChanType(NewChan)
                
                },
            false
        ))

        
        // Button event - тип дверцы
        ButtonsDoors.forEach(Button => Button.addEventListener(
            'click',
            function() { 
                
                NewChan.ChanDoor = eval(this.getAttribute('name'))

                this.classList.add("buttonActive")
                RemoveClass(Button,ButtonsDoors)
                ChanType(NewChan)         
            },
            false
        ))


         // Button event - материал печки
         ButtonsMat.forEach(Button => Button.addEventListener(
            'click',
            function() { 

                NewChan.ChanMat = eval(this.getAttribute('name'))
                this.classList.add("buttonActive")
                RemoveClass(Button,ButtonsMat)
                ChanType(NewChan)
                
                },
            false
        ))




        // Button event - тип спинки
        ButtonsSpinka.forEach(Button => Button.addEventListener(
            'click',
            function() { 

                NewChan.ChanBack = eval(this.getAttribute('name'))
                this.classList.add("buttonActive")
                RemoveClass(Button,ButtonsSpinka)
                ChanType(NewChan)

                },
            false
        ))        


        // Button event - тип лестницы
        ButtonsStairs.forEach(Button => Button.addEventListener(
        'click',
        function() { 
            NewChan.ChanStair = eval(this.getAttribute('name'))
            let scale = eval(NewChan.ChanSize.scale)
            let Stairz = gltf.scene.children.find((child) => child.name === NewChan.ChanStair.name)
            if (Stairz) { Stairz.scale.set(1+(0.1-scale)*10,1+(0.1-scale)*10,1) }
            this.classList.add("buttonActive")
            RemoveClass(Button,ButtonsStairs)
            ChanType(NewChan)
            },
        false
        ))         

        // Button event - тип тента
        ButtonsTent.forEach(Button => Button.addEventListener(
            'click',
            function() { 
                NewChan.ChanTop = eval(this.getAttribute('name'))
                
                this.classList.add("buttonActive")  

                gsap.to(TentMaterial, { duration: 0.5, delay: 0, opacity: 1 }) 
                gsap.to(TentMaterial, { duration: 0.5, delay: 4, opacity: 0 })

                
                gsap.to(WoodTentMaterial, { duration: 0.5, delay: 0, opacity: 1 })
                gsap.to(WoodTentMaterial, { duration: 0.5, delay: 4, opacity: 0 })


                gsap.to(TentChromMaterial, { duration: 0.5, delay: 0, opacity: 1 })
                gsap.to(TentRopeMaterial, { duration: 0.5, delay: 0, opacity: 1 })
                gsap.to(TentChromMaterial, { duration: 0.5, delay: 4, opacity: 0 })
                gsap.to(TentRopeMaterial, { duration: 0.5, delay: 4, opacity: 0 })
                   

                RemoveClass(Button,ButtonsTent)
                ChanType(NewChan)
                
                },
            false
            ))  

        // Button event - Стульчик

        ButtonsChair.forEach(Button => Button.addEventListener(
            'click',
            function() { 
                
                NewChan.ChanChair = eval(this.getAttribute('name'))

                gsap.to(water.material, {delay: 0, visible: 0 })  
                gsap.to(water.material, {delay: 6, visible: 1 })                 

                this.classList.add("buttonActive")
                RemoveClass(Button,ButtonsChair)
                ChanType(NewChan)         
            },
            false
        ))

           
 
        // console.log(gltf.scene)
        scene.add(gltf.scene)
        updateAllMaterials()
  
    }
      
)


/**
 * Environment map
 */
let envy ='0'
let prop="jpg"

 const environmentMap = cubeTextureLoader.load([
    'textures/environmentMaps/'+envy+'/px.'+prop,
    'textures/environmentMaps/'+envy+'/nx.'+prop,
    'textures/environmentMaps/'+envy+'/py.'+prop,
    'textures/environmentMaps/'+envy+'/ny.'+prop,
    'textures/environmentMaps/'+envy+'/pz.'+prop,
    'textures/environmentMaps/'+envy+'/nz.'+prop
])



// const chasha = scene.children.find((child) => child.name === 'chasha')
// chasha.material = chashaMaterial




/**
 * Models
 */
// const dracoLoader = new DRACOLoader()
// dracoLoader.setDecoderPath('/draco/')
// const gltfLoader = new GLTFLoader()
// gltfLoader.setDRACOLoader(dracoLoader)

let mixer = null

environmentMap.encoding = THREE.sRGBEncoding
scene.environment = environmentMap
// scene.environment.magFilter = THREE.LinearFilter

// environmentMap.magFilter = THREE.LinearFilter
// child.material.envMapIntensity = 10


/**
 * Floor
 */

const shadowMaterial = new THREE.ShadowMaterial()
shadowMaterial.opacity = 0.6

const floor = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(6, 6),shadowMaterial
)
floor.receiveShadow = true
floor.position.y =   0.01
floor.rotation.x = - Math.PI * 0.5
floor.name = floor
scene.add(floor)



/**
 * Lights
 */
// const ambientLight = new THREE.AmbientLight(0xffffff, 0.1)
// scene.add(ambientLight)


const BackdirectionalLight = new THREE.DirectionalLight(0x98A8FF, 0.6)
BackdirectionalLight.castShadow = false
BackdirectionalLight.position.set(2, 5, -7)
scene.add(BackdirectionalLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 1)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(2024, 2024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
 directionalLight.shadow.normalBias = 0.02
 directionalLight.shadow.radius = 5
 directionalLight.shadow.blurSamples = 25
directionalLight.position.set(2, 5, 7)
scene.add(directionalLight)

// 0x0a090e старый 0x1D1C1F 0x171517 0xF37C26




if (mainPage()) { 
    scene.background = environmentMap
}

else {
    scene.background = new THREE.Color( 0x171517 )
}


// const TermoFaceColorTexture = textureLoader.load('textures/termo.jpg')

// const directionalBack = new THREE.DirectionalLight(0xffffff, 1)
// directionalBack.castShadow = false
// directionalBack.position.set(0, 2, 7)
// scene.add(directionalBack)



window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    renderer.antialias = true

     // Update effect composer
     effectComposer.setSize(sizes.width, sizes.height)

         // Update fireflies
    firefliesMaterial.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2)
    // firefliesMaterial.uniforms.uSize.value = firefliesMaterial.uniforms.uSize.value*1/PropKoeff чтото надо делать с размером костра

    

    PropKoeff = sizes.height / sizes.width 
    camera.position.set(0, 1.2, 3.1+PropKoeff)

    // const Ancor = document.getElementById('anchor') 
    // Ancor.style.backgroundColor = "green"
})




// Controls
const controls = new OrbitControls(camera, canvas) 
controls.target.set(0, 1.2, 0)
controls.enableZoom = false;
controls.enablePan = false;
controls.enableDamping = true
 controls.enabled = true


 if (sizes.width < 600 ) {  


controls.autoRotate = false
controls.autoRotateSpeed = 0

}
if (sizes.width > 600 )  {


    controls.autoRotate = true
    controls.autoRotateSpeed = 1
}


// controls.minAzimuthAngle = - Math.PI / 2 
// controls.maxAzimuthAngle = Math.PI / 2 
controls.minPolarAngle =  Math.PI / 3
controls.maxPolarAngle = Math.PI / 1.7

if (mainPage()) { 

controls.minAzimuthAngle = - Math.PI / 5 
controls.maxAzimuthAngle = Math.PI / 5 
controls.minPolarAngle =  Math.PI / 2.3
controls.maxPolarAngle = Math.PI / 3.5

}


window.addEventListener('mousemove', (event) =>

{
     
    mouse.x = event.clientX / sizes.width * 2 - 1
    mouse.y = event.clientY / sizes.height * 2 - 1  

    controls.autoRotateSpeed  = mouse.x*2


    // console.log(mouse.x,mouse.y)

    // let vec = Vector3.lerp(new THREE.Vector3(-mouse.x*0.5, 1+mouse.y*0.5, 3.3),0.05)
    // controls.target.lerp(new THREE.Vector3(-mouse.x*0.2, 1+mouse.y*0.2, 0),0.01)
})

// курсор



/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true 
})

// renderer.antialias = false


renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
// renderer.shadowMap.type = THREE.VSMShadowMap
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ReinhardToneMapping

renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0



/**
 * Post processing
 */
 let RenderTargetClass = null

 if(renderer.getPixelRatio() === 1 && renderer.capabilities.isWebGL2)
 {
     RenderTargetClass = THREE.WebGLMultisampleRenderTarget
    //  console.log('Using WebGLMultisampleRenderTarget')
 }
 else
 {
     RenderTargetClass = THREE.WebGLRenderTarget
    //  console.log('Using WebGLRenderTarget')
 }

const renderTarget = new RenderTargetClass(
    800,
    600,
    {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBAFormat,
        encoding: THREE.sRGBEncoding
    }
)

// Effect composer
const effectComposer = new EffectComposer(renderer, renderTarget)
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
effectComposer.setSize(sizes.width, sizes.height)

// Render pass
const renderPass = new RenderPass(scene, camera)
effectComposer.addPass(renderPass)

// Dot screen pass
// const dotScreenPass = new DotScreenPass()
// dotScreenPass.enabled = false
// effectComposer.addPass(dotScreenPass)

const unrealBloomPass = new UnrealBloomPass()
unrealBloomPass.enabled = true
effectComposer.addPass(unrealBloomPass)


unrealBloomPass.strength = 1.66
unrealBloomPass.radius = 0.
unrealBloomPass.threshold = 0.73


// gui.add(unrealBloomPass, 'enabled')
// gui.add(unrealBloomPass, 'strength').min(0).max(2).step(0.001)
// gui.add(unrealBloomPass, 'radius').min(0).max(2).step(0.001)
// gui.add(unrealBloomPass, 'threshold').min(0).max(1).step(0.001)






const tick = () =>
{
    stats.begin()
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
   
    // Model animation
    if(mixer)
    {
        mixer.update(deltaTime)
    }

    // Update controls
      controls.update()


              for(const point of points)
              {
                  // Get 2D screen position
                  const screenPosition = point.position.clone()
                  screenPosition.project(camera)
          
          
                  const translateX = screenPosition.x * sizes.width * 0.5
                  const translateY = - screenPosition.y * sizes.height * 0.5
                  point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
              }


// Проверяем производительность у клиента
    let fps = stats.getFrameRate()
    if (fps < 30 ) {

        firefliesCount = 80
        firefliesMaterial.uniforms.uSize.value = 120
        // water.visible=false   
        renderer.render(scene, camera)

    } else{
        firefliesMaterial.uniforms.uSize.value = 250
        effectComposer.render()

    }


    firefliesMaterial.uniforms.uTime.value = elapsedTime

    SmokeMaterial.uniforms.uTime.value = elapsedTime

    // gl_PointCoord

    // Render
    // renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

    stats.end()

}
tick()